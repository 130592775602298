
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        flex-flow: column-reverse;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .no-scrollbar:last-child {
      /* margin-top: auto; */
    }
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.white');
  --default-cell-border-color: theme('colors.black');
  --default-cell-text-color: theme('colors.black');
  --absent-cell-bg-color: theme('colors.slate.400');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --default-cell-border-color: theme('colors.white');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

#correct-container::-webkit-scrollbar{
  display: none;
}

#correct-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#correct-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg:hover {
  animation: float infinite;
  animation-duration: var(--animation-speed);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}



@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50% {
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  }
  100% {
    transform: rotateX(180deg);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -0.5rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
